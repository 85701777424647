.feed-card-overlay {
    background: linear-gradient(180deg, rgba(31, 31, 31, 0) 42.19%, rgba(31, 31, 31, 0.916667) 80.94%, #1F1F1F 100%);
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
}

.feed-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    border-radius: 8px;
    padding: 2px;
    box-sizing: border-box;
}

.feed-card {
    position: relative;
    height: 200px;
    width: 100%;
}

.feed-card-edit-button {
    background-color: white;
    border: 1px solid #EE4139;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    border-radius: 8px;
    cursor: pointer;
    z-index: 10;
    align-items:center;
}

.feed-card-info {
    font-family: 'Lato', sans-serif;
    color: white;
    position: absolute;
    bottom: 0px;
    z-index: 10;
    padding: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.feed-card-datettime {
    font-size: 12px;
}

.feed-card-title {
    width: 100%;
    font-size: 18px;
    margin-top: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

.feed-to-upload-image{
    object-fit: contain;
    border-radius: 8px;
    padding: 5px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}