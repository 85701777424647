.home-parent {
    display: grid;
    grid-gap: 16px;
    justify-content: center;
    padding-bottom: 128px;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 800px) {
    .home-parent {
        grid-template-columns: 100%;
    }
}

.emergencyCardOverview-layout {
    animation: fadeIn 250ms;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(4px)
}

.max-width-adapt {
    width: 100%;
    /* max-width: 800px; */
}

.center-div {
    display: flex;
    justify-content: center;

}

.below-top-bar {
    margin-top: 48px;

}

.loading-bg {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(2px);
    top: 0;
    left: 0
}