@media screen and (min-width: 800px) {
    .feed-container {
        width: 740px;
    }

    .publish {
        width: 740px;
    }

    .save-content-button {
        width: 760px;
    }

    .dropdown {
        width: 750px;
    }

}

@media screen and (max-width: 800px) {
    .feed-container {
        width: 340px;
    }

    .publish {
        width: 340px;
    }

    .save-content-button {
        width: 360px;
    }

    .dropdown {
        width: 350px;
    }


}

.md-editor{
  /* min-height: 500px; */
  width: -webkit-fill-available;
  margin-left: 16px;
  margin-right: 16px;
}