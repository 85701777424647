@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

.more-btn{
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: min-content;
    padding: 8px;
    margin-top: 20px;
    cursor: pointer;
}

.more-btn:hover{
    background: rgba(255, 255, 255, 0.35);
}