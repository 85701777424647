@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
iframe{
  display: none;
}
/* .token{
  display: flex;
} */

.version{
  align-items: flex-end;
    bottom: 0.5em;
    display: flex;
    height: 100vh;
    justify-content: flex-end;
    opacity: .25;
    pointer-events: none;
    position: fixed;
    right: 0.5em;
    width: 100vw;
    color:#C4C4C4;
}

.css-grid{
   display: flex;           
  align-items: center;     
  justify-content: center;  
}
.hide-native-scrollbar {
  scrollbar-width: none;
  /* Firefox 64 */
  -ms-overflow-style: none;
  /* Internet Explorer 11 */
}

.hide-native-scrollbar::-webkit-scrollbar {
  /** WebKit */
  display: none;
}

body {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden
}

.standard-button {
  align-items: center;
  background-clip: padding-box;
  background-color: #fa6400;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(0.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.standard-button:hover,
.standard-button:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.standard-button:hover {
  transform: translateY(-1px);
}

.standard-button:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}

.defaultPage-bg {

  overflow-x: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(./img/icon/DefaultPageBackground.jpg);
  background-position: center;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  position: 'absolute';
  height: 40;
  left: 0;
  top: WINDOW_HEIGHT - 40;
  width: WINDOW_WIDTH;
}

.backdrop {
  background: #FFFFFF;
  border-radius: 1em;
  box-shadow: 16px, 16px, 8px, red;
}

.layout {
  --linear-layout-item-gap: 5px;
}

.sub-layout>* {
  --linear-layout-item-gap: 20px;
}

.input-text {
  color: black !important;
  border-color: yellowgreen !important;
}

.input-text::before {
  color: black !important;
  border-color: yellowgreen !important;
  border-bottom: 1px solid grey !important;

}

.input-text::after {
  color: black !important;
  border-color: yellowgreen !important;
  border-bottom: 2px solid #114B7A !important;

}

.input-text-container {
  color: #F5F6FA !important;
  border-color: yellowgreen !important;
  background-color: #F5F6FA;
  border-radius: 10px;
}

.input-text-label {
  color: #C4C4C4 !important;
  font-family: "Lato";
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.roll-out {
  @keyframes rollout {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(25px);
    }
  }

  animation: rollout 0.5s;
}

.roll-out-mobile {
  @keyframes rolloutmobile {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(15px);
    }
  }

  animation: rolloutmobile 0.5s;
}

.roll-in {
  @keyframes rollin {
    0% {
      transform: translateX(25px);
    }

    100% {
      transform: translateX(0px);
    }
  }

  animation: rollin 0.5s;
}

.roll-in-mobile {
  @keyframes rollinmobile {
    0% {
      transform: translateX(15px);
    }

    100% {
      transform: translateX(0px);
    }
  }

  animation: rollinmobile 0.5s;
}

.fade-in-element-a {
  @keyframes fade-in-a {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-a 0.5s;
}

.fade-in-element-b {
  @keyframes fade-in-b {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-b 0.5s;
}

.fade-in-element-c {
  @keyframes fade-in-c {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-c 0.5s;
}

.fade-in-element-d {
  @keyframes fade-in-d {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-d 0.5s;
}

.fade-in-element-e {
  @keyframes fade-in-e {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in-e 0.5s;
}

.ripple-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}

.ripple-emitter {
  position: relative;
  margin: 0 auto;
  border-radius: 9999px;
  background-image: linear-gradient(to right, white 0%, #fee140 100%);
}

.ripple-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-image: linear-gradient(to right, white 0%, #fee140 100%);
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}

.minimize-dashboard {
  max-width: 1000px;
  animation: minimizeDashboard 20.5s
}

.maximize-dashboard {
  animation: minimizeDashboard 0.5s reverse
}

.dashboard-container {
  width: 100vw;
  display: flex;
  justify-content: center;
}



#custom-scroll::-webkit-scrollbar {
  width: 0px;
  background-color: #F5F5F520;
}

#custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #FFDACC;
}

.voucher-bar-offer {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.ripple-wave {
  animation: rippleWave 4s ease-in infinite;
}


.ripple-float {
  animation: rippleFloat 4s ease-in infinite;
}

@keyframes rippleWaveBG {
  0% {
    opacity: 0;
    transform: scale(0.075) translateY(20vh);
    filter: blur(0.25em);
  }

  50% {
    opacity: 0.125;
    filter: blur(1em)
  }

  100% {
    transform: scale(1) translateY(-10vh);
    opacity: 0;
    filter: blur(0.5em);
  }
}

@keyframes rippleWaveMG {
  0% {
    opacity: 0;
    transform: scale(0.075) translateY(20vh);
    filter: blur(0.05em);
  }

  50% {
    opacity: 0.125;
    filter: blur(0.2em)
  }

  100% {
    transform: scale(1) translateY(-40vh);
    opacity: 0;
    filter: blur(0.1em);
  }
}

@keyframes rippleWaveFG {
  0% {
    opacity: 0;
    transform: scale(0.075) translateY(20vh);
  }

  50% {
    opacity: 0.125;
  }

  100% {
    transform: scale(1) translateY(-80vh);
    opacity: 0;
  }
}

@keyframes rippleFloat {
  0% {
    transform: translateY(-20vh);
  }

  100% {
    transform: translateY(-60vh);
  }
}

@keyframes minimizeDashboard {
  0% {
    width: 100vw;
  }

  100% {
    width: 900px;
  }
}

@keyframes maximizeDashboard {
  0% {
    width: 900px;
  }

  100% {
    width: 100vw;
  }
}

@keyframes minimize-info-text {
  0% {
    font-size: 28px;
  }

  100% {
    font-size: 20px;
  }
}


@keyframes maximize-info-text {
  0% {
    font-size: 20px;
  }

  100% {
    font-size: 28px;
  }
}